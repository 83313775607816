import React from 'react';
import './App.scss';
import LogoSVG from './logo.svg'

function App() {
  return (
    <div className="App">
      <div className="Box">
        <img src={LogoSVG} alt="GloPerformance" />
        <div className="Title">GloPerformance Consulting Group Pte Ltd</div>
        <p className="Quote">
        We are currently rebuilding our website to provide you a better experience and understanding of our training and consultancy services.
        </p>
        <p className="Large">
        Contact us at <a href="mailto:chowhan@gloperformance.com">chowhan@gloperformance.com</a>
        </p>
      </div>
    </div>
  );
}

export default App;
